<template>
  <div id="wrapper">
    <nav class="navbar is-dark" role="navigation">
      <div class="navbar-brand">
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic" @click="showMobile = !showMobile">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasic" class="navbar-menu" :class="{'is-active': showMobile}">
        <div class="navbar-start">
          <!--<router-link class ="navbar-item" to="/" @click="showMobile = false">Home</router-link>-->
          <router-link class ="navbar-item" to="/" @click="showMobile = false">Projects</router-link>
          <router-link class ="navbar-item" to="/opensource" @click="showMobile = false">Open Source</router-link>
          <router-link class ="navbar-item" to="/about" @click="showMobile = false">About</router-link>
        </div>
      </div>
    </nav>
  </div>
  <router-view/>
</template>

<script>
export default {
  data(){
    return {
      showMobile:false
    }
  },
}
</script>

<style lang="scss">
@import '../node_modules/bulma'
</style>
