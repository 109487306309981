<template>
  <div class="home">
    <div class="columns">
      <div class="column">
        <div class="box">
          <div class="content">
            <p>Check out my projects or go to about for contact information</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
